import React from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead from "../EnhancedTableHead";
import UserAction from "../UserAction";

const formatDateAsText = inputDate => {
  if (!inputDate) {
    return "-";
  }

  const date = moment(new Date(inputDate), "YYYY-MM-DD HH:mm");
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  const wasUpdatedToday = date.isSame(today, "d");
  const wasUpdatedYesterday = date.isSame(yesterday, "d");
  const updatedDate = date.format("YYYY-MM-DD");
  const updatedHours = date.format("HH:mm");

  return (
    <React.Fragment>
      {wasUpdatedToday ? "Idag" : wasUpdatedYesterday ? "Igår" : updatedDate}
      <em
        style={{
          display: "block",
          color: "#95a4ac",
          fontStyle: "normal",
          lineHeight: "15px"
        }}
      >
        {updatedHours}
      </em>
    </React.Fragment>
  );
};

const LoggedInNowTable = props => {
  const {
    loaded,
    columns,
    logs,
    clients,
    handleRequestSort,
    handleClientClick
  } = props;

  return (
    <Box
      sx={{
        width: "100%"
      }}
    >
      <TableContainer component={Box} sx={{ overflowY: "hidden" }}>
        <Box
          sx={{
            display: "block",
            width: "100%",
            height: loaded ? "unset" : "400px",
            // minHeight:
            //   customersTableHeightPerTotalPages[rowsPerPage] || "unset",
            // border: 0,
            overflow: "auto"
          }}
        >
          {!loaded ? (
            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="button" size={36} />
            </Box>
          ) : (
            <Table
              aria-label="custom pagination table"
              sx={{
                mt: 1,
                border: 0
              }}
            >
              <EnhancedTableHead
                columns={columns}
                order={"desc"}
                orderBy={""}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {logs.map((entry, key) => (
                  <TableRow
                    key={key}
                    sx={{
                      height: 48,
                      ...(key % 2 !== 0 && {
                        "& > td": { background: "#f5f9ff" }
                      })
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.5,
                        borderBottom: "none",
                        color: "secondary.lighter",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      <Typography variant="tableCell">
                        {formatDateAsText(entry.Created)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        py: 0.5,
                        borderBottom: "none",
                        color: "secondary.lighter"
                      }}
                    >
                      <Typography variant="tableCell">
                        <span>
                          {entry.UserFirstName} {entry.UserLastName}
                        </span>
                        <em
                          style={{
                            display: "block",
                            color: "#95a4ac",
                            fontStyle: "normal",
                            lineHeight: "15px"
                          }}
                        >
                          {entry.ResellerName}
                        </em>
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        py: 0.5,
                        borderBottom: "none",
                        color: "secondary.lighter",
                        textAlign: "right"
                      }}
                    >
                      <Typography variant="tableCell">
                        <UserAction
                          log={entry}
                          clients={clients}
                          handleClientClick={handleClientClick}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </TableContainer>
    </Box>
  );
};

export default LoggedInNowTable;

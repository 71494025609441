import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ContentBox from "@components/ContentBox/ContentBox";
import CustomerModal from "@cobComponents/CustomerModal/CustomerModal";
import AddCustomerModal from "@cobComponents/AddCustomerModal";

import useUserActivityView from "./useUserActivityView";
import AllActivityTable from "./AllActivityTable/AllActivityTable";
import LoggedInNowTable from "./LoggedInNowTable/LoggedInNowTable";

const UserActivityView = props => {
  const {
    isMobileOrTablet,
    user,
    partner,
    advisor,
    activityLogsLoaded,
    currentlyLoggedLoaded,
    allActivityColumns,
    loggedNowColumns,
    activityLogs,
    currentlyLogged,
    users,
    clients,
    numCases,
    page,
    setPage,
    filters,
    setFilters,
    order,
    orderBy,
    rowsPerPage,
    selectedCustomer,
    customerModalOpen,
    addCustomerModalOpen,
    setRowsPerPage,
    setSelectedCustomer,
    handleChangePage,
    handleRequestSort,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    refreshLogs,
    caseFilterContext,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions
  } = useUserActivityView(props);    

  return (
    <Box
      sx={{
        alignItems: "flex-start"
      }}
    >
      <ContentBox
        style={{
          width: "98.5%",
          margin: 0,
          marginBottom: "22.5px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #ededed",
            pb: 1.5,
            mb: 2
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: 500,
              color: "primary.main",
              lineHeight: 0.9
            }}
          >
            Inloggade just nu ({currentlyLogged.length})
          </Typography>
        </Box>
        <LoggedInNowTable
          loaded={currentlyLoggedLoaded}
          columns={loggedNowColumns}
          logs={currentlyLogged}
          clients={clients}
          handleRequestSort={handleRequestSort}
          handleClientClick={handleClientClick}
        />
      </ContentBox>
      <ContentBox
        style={{
          width: "98.5%",
          margin: 0,
          marginBottom: "22.5px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #ededed",
            pb: 1.5,
            mb: 2
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "18px",
              fontWeight: 500,
              color: "primary.main",
              lineHeight: 0.9
            }}
          >
            All användaraktivitet
          </Typography>
        </Box>
        <AllActivityTable
          isMobileOrTablet={isMobileOrTablet}
          loaded={activityLogsLoaded}
          columns={allActivityColumns}
          logs={activityLogs}
          users={users}
          clients={clients}
          numCases={numCases}
          page={page}
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          selectedCustomer={selectedCustomer}
          customerModalOpen={customerModalOpen}
          addCustomerModalOpen={addCustomerModalOpen}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          setSelectedCustomer={setSelectedCustomer}
          handleClientClick={handleClientClick}
        />
      </ContentBox>
      {customerModalOpen && selectedCustomer && (
        <CustomerModal
          view="overview"
          visible={true}
          user={user}
          partner={partner}
          advisor={advisor}
          client={selectedCustomer}
          onClose={handleClientModalClose}
          updateCustomers={refreshLogs}
          caseFilterContext={caseFilterContext}
          addCustomerModalOpen={addCustomerModalOpen}
          toggleAddCustomerModal={toggleAddCustomerModal}
          setCustomerState={setCustomerState}
          onWorkflowSwitched={onWorkflowSwitched}
          nextStepOptions={nextStepOptions}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
      {addCustomerModalOpen && (
        <AddCustomerModal
          open={addCustomerModalOpen}
          toggleModal={toggleAddCustomerModal}
          customer={selectedCustomer}
          userData={{ personal_number: advisor.ssn, role: advisor.role }}
          updateCustomers={refreshLogs}
          allowNoCustomerId={partner._data.allowNoCustomerId}
        />
      )}
    </Box>
  );
};

export default UserActivityView;

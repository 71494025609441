import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import TextField from "@components/TextField/TextField";
import TextView from "@components/TextView/TextView";
import ExpandableRowContainer from "@cobComponents/ExpandableRowContainer";
import Button from "@components/Button/Button";

import i18n from "@helpers/i18n";

const ExpandableRow = props =>
{
	const {
    error,
		colSpan,
		whitelabel,
		entry,
		user,
		partner,
		type,
		onAction,
		msgTitleRef,
		msgTextRef,
		onMessage,
    currentAction,
    resetPaginationAndFilters
	} = props;

	return (
		<TableRow sx={{
			borderTop: "1px solid #cfdaf0",
			borderBottom: "2px solid #f7f7f7"
		}}>
			<TableCell colSpan={colSpan} sx={{
				pl: "0",
				borderBottom: "none",
				padding: "10px",
				background: "#fffdf9",
			}}>
        {error ? (
          <div style={{padding: "91px 0"}}>Ett fel uppstod när data laddades, kontakta supporten.</div>
        ) : (
          <Fragment>
            {entry?.loading ? (
						<div style={{
							textAlign: "center",
							padding: "15px 0"
						}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
            ) : (
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                padding: "0"
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "stretch",
                  width: "100%",
                  padding: "0"
                }}>
                  <div style={{
                    marginRight: "10px",
                    paddingRight: "10px",
                    borderRight: "1px solid #cfdaf0"
                  }}>
                    {(type === "main" || type === "other") && (
                      <Fragment>
                        {(user.role === "backoffice" || ((user.role === "administrator" || user.role === "advisor" || user.role === 'assistant') && entry.state !== "draft")) && (
                          <Button className="expandable-menu-button"
                                  appearance="light"
                                  label="Öppna (skrivskyddat)"
                                  busy={(currentAction === "open-read-only") || false}
                                  onClick={onAction.bind(this, "open-read-only", entry.caseId, entry.ssn)}
                                  filled/>                          
                        )}
                      </Fragment>
                    )}
                    {(type === "main" || type === "futur") &&
                      entry.state === "draft" &&
                      user.role !== "backoffice" &&
                      (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                        <React.Fragment>
                          <Button className="expandable-menu-button"
                                  appearance="light"
                                  label={i18n("table_container", "continue_consultation")}
                                  busy={(currentAction === "continue") || false}
                                  onClick={onAction.bind(this, "continue", entry.caseId, entry.ssn)}
                                  filled/>

                          {user.role !== "assistant" && !entry.is_shared_case && (
                            <Button className="expandable-menu-button"
                                    appearance="light"
                                    label={i18n("table_container", "remove_draft")}
                                    busy={(currentAction === "delete") || false}
                                    onClick={onAction.bind(this, "delete", entry.caseId, entry.ssn)}
                                    filled/>
                          )}
                        </React.Fragment>
                      )
                    }

                    {/* Sessions (main) or Other table */}
                    {(type === "main" || type === "other") && (
                      <Fragment>
                        {(
                            (type === "main" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", ""))) ||
                            (entry.state === "sent") ||
                            (type === "main" && entry.state === "sent" && user.hasPermission("case.change_state")) ||
                            (entry.state === "signed" && (user.hasPermission("case.download")))
                          )
                          && (
                            <React.Fragment>
                              {type === "main" &&
                                user.role !== "backoffice" &&
                                (entry.state === "signed" || entry.state === "sent" || entry.state === "recalled") &&
                                (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) &&
                                (
                                  <Button className="expandable-menu-button"
                                          appearance="light"
                                          label={i18n("table_container", "copy_consultation")}
                                          busy={(currentAction === "copy") || false}
                                          onClick={onAction.bind(this, "copy", entry.caseId, entry.ssn)}
                                          filled/>
                                )}

                              {entry.state === "sent" && (
                                <Button className="expandable-menu-button"
                                        appearance="light"
                                        label={i18n("table_container", "send_reminder")}
                                        busy={(currentAction === "remind") || false}
                                        onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}
                                        filled/>
                              )}

                              {user.role !== "assistant" && entry.state === "sent" && (
                                <Button className="expandable-menu-button"
                                        appearance="light"
                                        label={i18n("table_container", "revoke")}
                                        busy={(currentAction === "recall") || false}
                                        onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn,resetPaginationAndFilters)}
                                        filled/>
                              )}

                              {type === "main" && entry.state === "sent" && user.hasPermission("case.change_state") && (
                                <Button className="expandable-menu-button"
                                        appearance="light"
                                        label={i18n("table_container", "in_progress")}
                                        busy={(currentAction === "processing") || false}
                                        onClick={onAction.bind(this, "processing", entry.caseId, entry.ssn, resetPaginationAndFilters)}
                                        filled/>
                              )}

                              {entry.state === "signed" && (user.hasPermission("case.download") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                                <Button className="expandable-menu-button"
                                        appearance="light"
                                        label={`${i18n("table_container", "download_documentation")} (PDF)`}
                                        busy={(currentAction === "download") || false}
                                        onClick={onAction.bind(this, "download", entry.caseId, entry.ssn)}
                                        filled/>
                              )}
                            </React.Fragment>
                          )}
                      </Fragment>
                    )}

                    {/* Sessions (main) */}
                    {type === "main" && entry.state === "processing" && user.hasPermission("case.change_state") && (
                      <React.Fragment>
                        <Button
                          className="expandable-menu-button"
                          appearance="light"
                          label={i18n("table_container", "reset_to_sent")}
                          busy={(currentAction === "unprocessing") || false}
                          onClick={onAction.bind(this, "unprocessing", entry.caseId, entry.ssn, resetPaginationAndFilters)}
                          filled
                        />
                        <Button
                          className="expandable-menu-button"
                          appearance="light"
                          label={i18n("table_container", "revoke")}
                          busy={(currentAction === "recall") || false}
                          onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn)}
                          filled
                        />
                      </React.Fragment>
                    )}
                    
                    {/* Futur */}
                    {type === "futur" && (
                      <Fragment>
                        {(entry.state === "sent" || entry.downloadable_meeting) && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container", "copy_consultation")}
                            busy={(currentAction === "copy") || false}
                            onClick={onAction.bind(this, "copy", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
          
                        {whitelabel === "fair" && entry.state === "sent" && !entry.downloadable_meeting && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container_futur", "send_meeting_attachment_reminder")}
                            busy={(currentAction === "remind_meeting") || false}
                            onClick={onAction.bind(this, "remind_meeting", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
          
                        {whitelabel === "fair" && entry.state === "sent" && !entry.downloadable_account && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container_futur", "send_futur_application_reminder")}
                            busy={(currentAction === "remind") || false}
                            onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
          
                        {whitelabel !== "fair" && entry.state === "sent" && !entry.downloadable_account && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container_futur", "send_reminder")}
                            busy={(currentAction === "remind") || false}
                            onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
          
                        {entry.downloadable_meeting && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={`${i18n("table_container_futur", "send_reminder")} (PDF)`}
                            busy={(currentAction === "download_meeting") || false}
                            onClick={onAction.bind(this, "download_meeting", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
          
                        {entry.downloadable_account && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={`${i18n("table_container_futur", "download_futur_application")} (PDF)`}
                            busy={(currentAction === "download_futur") || false}
                            onClick={onAction.bind(this, "download_futur", entry.caseId, entry.ssn)}
                            filled
                          />
                        )}
                      </Fragment>
                    )}
                    
                    {/* Poa */}
                    {type === "poa" && (    
                      <Fragment>
                        {entry.state === "sent" && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container", "send_reminder")}
                            busy={(currentAction === "remind") || false}
                            onClick={onAction.bind(this, "remind", entry.poa_id, entry.personal_number)}
                            filled
                          />
                        )}
                        {entry.state === "signed" && (
                          <Button
                            className="expandable-menu-button"
                            appearance="light"
                            label={i18n("table_container", "revoke")}
                            busy={(currentAction === "revoke") || false}
                            onClick={onAction.bind(this, "revoke", entry.poa_id)}
                            filled
                          />
                        )}                      
                      </Fragment>
                    )}            
                  </div>

                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    flex: "1",
                    padding: "0"
                  }}>
                    {entry.signatories && entry.signatories.length > 0 && (
                      <ExpandableRowContainer title={i18n("table_container", "signatories")}>
                        <ul className={"case-signatories"}>
                          {entry.signatories.map((party, key) =>
                          {
                            const rowSigned = entry.state === "signed" || party.Signed;
                            return (
                              <li className={rowSigned ? "signed" : ""}
                                  style={{width: "100%"}}
                                  key={key}>
                                <span className={"box"}>
                                {rowSigned && (
                                  <React.Fragment>
                                    &#10003;
                                  </React.Fragment>
                                )}
                                </span>

                                <div style={{display: "block", flex: "1"}}>
                                  <span className={"name"}>
                                    {party.Name}
                                  </span>

                                  {party.Signed && (
                                    <span className={"date"}>
                                      {i18n("table_container", "signed")}: {party.Signed}
                                    </span>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>

                        {(type === "main" || type === "other") &&
                          entry.state === "sent" &&
                          partner.partnerId === 'fair' &&
                          entry.waiting_for_final_signature &&
                          user.hasPermission("case.sign_bo") && (
                            <Button className="expandable-menu-button"
                                    appearance="light"
                                    label={i18n("table_container", "mark_as_signed_by_backoffice")}
                                    busy={(currentAction === "sign_bo") || false}
                                    onClick={onAction.bind(this, "sign_bo", entry.caseId, entry.ssn, resetPaginationAndFilters)}
                                    filled/>
                          )}
                      </ExpandableRowContainer>
                    )}

                    {/* Sessions (main) or Other table */}
                    {(type === "main" || type === "other") && (
                      <Fragment>
                        {entry.commission_sharing && entry.commission_sharing.length > 0 && (
                          <ExpandableRowContainer
                            title={i18n("table_container", "commission_distribution")}>
                            <ul style={{margin: "0", padding: "0", width: "100%"}}>
                              {entry.commission_sharing.map((party, key) =>
                              {
                                return (
                                  <li key={key} style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row"
                                  }}>
                                    <span style={{flex: 1}}>
                                      {party.advisor}
                                    </span>
                                    <span style={{color: "#0a6b00", fontWeight: "bold"}}>
                                      {party.share}
                                    </span>
                                  </li>
                                )
                              })}
                            </ul>
                          </ExpandableRowContainer>
                        )}
    
                        {entry.audit.length > 0 && user.hasPermission("case.audit_log") && (
                          <ExpandableRowContainer
                            title={i18n("table_container", "log")}>
                            <ul className={"audit"}>
                              {entry.audit.map((entry, key) =>
                              {
                                return (
                                  <li key={key}>
                                    <span className={"created"}>{entry.CreatedForDisplay}</span>
                                    <span className={"originator"}>{entry.OriginatorName}</span>
                                    <span className={"action"}>
                                      {entry.Action === "created" && (
                                        <React.Fragment>
                                          {i18n("table_container", "case_created")} {entry.caseId}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "signed" && (
                                        <React.Fragment>
                                          {i18n("table_container", "case_signed")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "sent_for_sign" && (
                                        <React.Fragment>
                                          {i18n("table_container", "sent_for_signing")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "finished" && (
                                        <React.Fragment>
                                          {i18n("table_container", "marked_as_signed_by_all")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "opened" && (
                                        <React.Fragment>
                                          {i18n("table_container", "case_opened")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "reminded" && (
                                        <React.Fragment>
                                          {i18n("table_container", "sent_reminder")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "recalled" && (
                                        <React.Fragment>
                                          {i18n("table_container", "case_revoked")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "download" && (
                                        <React.Fragment>
                                          {i18n("table_container", "downloaded_documentation")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "download_futur" && (
                                        <React.Fragment>
                                          {i18n("table_container", "downloaded_documentation_futur")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "processing_set" && (
                                        <React.Fragment>
                                          {i18n("table_container", "set_status_in_progress")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "processing_unset" && (
                                        <React.Fragment>
                                          {i18n("table_container", "reset_status_in_progress")}
                                        </React.Fragment>
                                      )}
    
                                      {entry.Action === "copied" && (
                                        <React.Fragment>
                                          {i18n("table_container", "copied_case")}
                                        </React.Fragment>
                                      )}
                                                                </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </ExpandableRowContainer>
                        )}
    
                        {entry.message_to_bo !== null && entry.message_to_bo !== "" && (
                          <ExpandableRowContainer
                            title={i18n("table_container", "message_to_backoffice")}>
                            <p style={{margin: "0"}}>
                              {entry.message_to_bo}
                            </p>
                          </ExpandableRowContainer>
                        )}
    
                        {(entry.state === "processing" || entry.state === "sent") && (
                          <div style={{width: "100%", display: "block"}}>
                            {(user.hasPermission("case.msgs_write") || entry.messages.length > 0) && (
                              <h4 style={{marginBottom: "15px"}}>
                                {i18n("table_container", "messages_from_backoffice")}
                              </h4>
                            )}
                            {entry.messages.length > 0 && (
                              <ul className={"messages"}>
                                {entry.messages.map((msg, key) =>
                                {
                                  return (
                                    <li key={key}>
                                      <div>
                                    <span className="msg-created">
                                      {msg.Created}
                                    </span>
                                        <span className="msg-title">
                                      {msg.Subject}
                                    </span>
                                      </div>
                                      <p>
                                        {msg.Message}
                                        <span className={"msg-originator"}>
                                      - {msg.OriginatorName}
                                    </span>
                                      </p>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
    
                            {entry.messages.length === 0 && (
                              <p>
                                <em>{i18n("table_container", "no_case_messages")}</em>
                              </p>
                            )}
    
                            {user.hasPermission("case.msgs_write") && (
                              <React.Fragment>
                                <strong style={{
                                  display: "block",
                                  fontSize: "13px",
                                  borderTop: "1px solid #EEE",
                                  paddingTop: "20px",
                                  margin: "20px 0 10px 0"
                                }}>{i18n("table_container", "new_message")}</strong>
    
                                <TextField ref={msgTitleRef}
                                          label={i18n("table_container", "title")}
                                          placeholder={i18n("table_container", "enter")}
                                          style={{margin: "0 0 10px 0", maxWidth: "300px"}}
                                          validation={value => value.length > 0}/>
    
                                <TextView ref={msgTextRef}
                                          label={i18n("table_container", "text")}
                                          placeholder={i18n("table_container", "enter")}
                                          maxLength={750}
                                          validation={value => value.length > 0}
                                          resize="vertical"
                                          fullWidth
                                          minHeight={250}
                                          style={{margin: "0 0 20px 0", maxWidth: "480px"}}/>
    
                                <Button className="expandable-menu-button"
                                        appearance="light"
                                        filled
                                        label={i18n("table_container", "save")}
                                        onClick={() =>
                                        {
                                          const title = msgTitleRef.current.value;
                                          const text = msgTextRef.current.value;
                                          if (title.length === 0 || text.length === 0)
                                          {
                                            return;
                                          }
                                          onMessage.call(this, entry.caseId, msgTitleRef.current.value, msgTextRef.current.value);
                                        }}/>
                              </React.Fragment>
                            )}
                          </div>
                        )}
                      </Fragment>
                    )}

                    {/* Futur */}
                    {type === "futur" && entry.signatories_futur.length > 0 && (
                      <ExpandableRowContainer title={`${i18n("table_container", "signatories")}: : Futur`}>
                        <ul className={"case-signatories"}>
                          {entry.signatories_futur.map(party =>
                            {
                              const rowSigned = entry.FuturStatus === "processed" || party.Signed;
                              return (
                                <li className={rowSigned ? "signed" : ""} key={entry.caseId}
                                    style={{width: "100%"}}>
                                  <span className={"box"}>
                                    {rowSigned && (
                                      <React.Fragment>
                                        &#10003;
                                      </React.Fragment>
                                    )}
                                  </span>

                                  <div style={{display: "block", flex: "1"}}>
                                    <span className={"name"}>
                                      {party.Name}
                                    </span>

                                    {party.Signed && (
                                      <span className={"date"}>
                                        ({i18n("table_container", "signed")}: {party.Signed})
                                      </span>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </ExpandableRowContainer>
                    )}

                    {/* Poa */}
                    {type === "poa" && entry.companies.length > 0 && (
                      <ExpandableRowContainer title={i18n("table_container_poas", "response_insurance")}>
                        <ul style={{margin: "0", padding: "0", width: "100%"}}>
                          {entry.companies.map((c, key) =>
                            {
                              return (
                                <li key={key} style={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "row"
                                }}>
                                  <span style={{flex: 1}}>
                                    {c.company}
                                  </span>
                                  <span>
                                    {c.is_customer === null && (
                                      <span>
                                        {i18n("table_container_poas", "sent")}
                                      </span>
                                    )}

                                    {c.is_customer === '0' && (
                                      <span style={{color: "#999"}}>
                                        {i18n("table_container_poas", "not_customer")}
                                      </span>
                                    )}

                                    {c.is_customer === '1' && (
                                      <span style={{color: "#0a6b00", fontWeight: "bold"}}>
                                        {i18n("table_container_poas", "customer")}
                                      </span>
                                    )}
                                  </span>
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </ExpandableRowContainer>
                    )}
                  </div>
                </Box>
              </Box>
            )}
          </Fragment>
        )}
			</TableCell>
		</TableRow>
	);
};

export default ExpandableRow;
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Link from "@mui/material/Link";

const UserAction = props => {
  const { log, clients, handleClientClick } = props;  

  const customer = log?.ClientId
    ? clients.find(client => client.Ssn === log.ClientId)
    : null;

  const customerName = customer
    ? customer.Name
      ? customer.Name
      : customer.FirstName + " " + customer.LastName
    : "";

  let action = "";
  switch (log.Action) {
    case "USER_AUTHENTICATED":
      action = `Loggade in`;
      break;
    case "CASE_CREATED":
      action = `Skapade ärende för kund `;
      break;
    case "CASE_OPEN":
      action = `Öppnade ärende för kund`;
      break;
    case "CASE_COPIED":
      action = `Kopierade ärende för kund`;
      break;
    case "CASE_SIGNED":
      action = `Signerade ärende för kund`;
      break;
    default:
      action = log.Action;
      break;
  }

  return (
    <span>
      {action}{" "}
      {customer && customerName && (
        <Link
          href="#"
          onClick={handleClientClick(customer.Ssn)}
          color="primary"
          underline="always"
        >
          {customerName}
        </Link>
      )}
    </span>
  );
};

UserAction.propTypes = {
  log: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  handleClientClick: PropTypes.func.isRequired
};

export default UserAction;

import React from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import WidgetTabs from "@components/WidgetTabs";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MUI_TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import i18n from "@helpers/i18n";
import { formatSSN } from "@helpers/utils";
import {
  getComparator,
  customersTableHeightPerTotalPages
} from "@helpers/muiTable";

import ContentBox from "@components/ContentBox/ContentBox";
import ButtonAdd from "@components/ButtonAdd";
import EnhancedTableHead from "@cobComponents/EnhancedTableHead";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";

import useUsersTable from "./useUsersTable";
import UsersTableMenu from "./UsersTableMenu";
import AddUserModal from "./AddUserModal";
import UserStatusModal from "./UserStatusModal";

const formatDateAsText = inputDate => {
  if (!inputDate) {
    return "-";
  }

  const date = moment(new Date(inputDate), "YYYY-MM-DD HH:mm");
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  const wasUpdatedToday = date.isSame(today, "d");
  const wasUpdatedYesterday = date.isSame(yesterday, "d");
  const updatedDate = date.format("YYYY-MM-DD");
  const updatedHours = date.format("HH:mm");

  return (
    <React.Fragment>
      {wasUpdatedToday ? "Idag" : wasUpdatedYesterday ? "Igår" : updatedDate}
      <em
        style={{
          display: "block",
          color: "#95a4ac",
          fontStyle: "normal",
          lineHeight: "15px"
        }}
      >
        {updatedHours}
      </em>
    </React.Fragment>
  );
};

const UsersTable = props => {
  const {
    loaded,
    mainUser,
    options,
    columns,
    roles,
    tabs,
    activeTab,
    copyList,
    resellers,
    page,
    order,
    orderBy,
    rowsPerPage,
    selectedUser,
    setPage,
    setSelectedUser,
    setRowsPerPage,
    handleTabChange,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddUserModal,
    toggleUserStatusModal,
    updateUsers,
    addUserModalOpen,
    userStatusModalOpen,
    newUserAdded
  } = useUsersTable(props);

  // converting last_login to timestamps(numbers) for sorting to work
  copyList.map(user => {
    user.last_login = new Date(user.last_login).getTime() || 0;
  });

  return (
    <ContentBox
      style={{
        width: "98.5%",
        margin: 0,
        marginBottom: "22.5px"
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #ededed",
          pb: 1.5,
          mb: 2
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: 500,
            color: "primary.main",
            lineHeight: 0.9
          }}
        >
          Alla systemanvändare
        </Typography>
        <ButtonAdd
          label="Skapa ny användare"
          onClick={() => toggleAddUserModal("add")}
        />
      </Box>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 1
          }}
        >
          <MUI_TextField
            size="small"
            fullWidth
            placeholder="Sök på namn, personnummer, roll, ombud"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25,
                      opacity: 0.5
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%"
              }
            }}
            sx={{
              width: "35%",
              display: "flex"
            }}
          />
          <WidgetTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            width="30%"
          />
        </Box>
        {newUserAdded && (
          <div
            className="sign-on-screen-notice Notice warning custom-message"
            style={{ maxWidth: "unset" }}
          >
            <Typography variant="body2" color="primary.main">
              <b>
                Sparad användare: {newUserAdded.first_name}{" "}
                {newUserAdded.last_name} ({newUserAdded.personal_number || "-"})
              </b>
            </Typography>
          </div>
        )}
        <TableContainer component={Box} sx={{ overflowY: "hidden" }}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              height: loaded ? "unset" : "400px",
              minHeight:
                customersTableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto"
            }}
          >
            {!loaded ? (
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="button" size={36} />
              </Box>
            ) : (
              <Table
                aria-label="custom pagination table"
                sx={{
                  mt: 1,
                  border: 0
                }}
              >
                <EnhancedTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? copyList
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                    : copyList.sort(getComparator(order, orderBy))
                  )
                    // (copyList.length > 0 ? copyList : rows)
                    .map((user, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          height: 48,
                          ...(key % 2 !== 0 && {
                            "& > td": { background: "#f5f9ff" }
                          })
                        }}
                      >
                        <TableCell
                          sx={{
                            pl: 0.5,
                            pr: 0,
                            py: 0,
                            borderBottom: "none"
                          }}
                        >
                          <UsersTableMenu                          
                            isSelf={mainUser.ssn === user.personal_number}
                            user={user}
                            setSelectedUser={setSelectedUser}
                            toggleAddUserModal={toggleAddUserModal}
                            toggleUserStatusModal={toggleUserStatusModal}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {i18n(
                            "user_roles",
                            Object.keys(roles).find(
                              role => roles[role] == user.role_id
                            )
                          )}
                          {user.is_active !== "1" && (
                            <em
                              style={{
                                display: "block",
                                color: "red",
                                fontStyle: "normal",
                                lineHeight: "15px"
                              }}
                            >
                              Inaktiv
                            </em>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {formatSSN(user.personal_number, { withDash: true })}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {user.first_name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {user.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {user.email}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            textAlign: "left",
                            pr: 1
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {user.reseller_id &&
                              resellers.find(
                                reseller =>
                                  reseller.reseller_id == user.reseller_id
                              )?.company_name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            color: "inherit"
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {formatDateAsText(user.last_login)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {copyList.length < 1 && (
                    <TableRow>
                      <TableCell sx={{ border: 0 }} colSpan={5}>
                        Inga resultat.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
          <CustomTableFooter
            loaded={true}
            type="users"
            totalCases={copyList.length}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
      {addUserModalOpen && (
        <AddUserModal
          isSelf={selectedUser?.personal_number === mainUser.ssn}
          showFuturKod={options?.showFuturKod}
          showCustomerNumber={options?.showCustomerNumber}
          user={selectedUser}
          roles={roles}
          resellers={resellers}
          updateUsers={updateUsers}
          toggleModal={toggleAddUserModal}
        />
      )}
      {userStatusModalOpen && (
        <UserStatusModal
          user={selectedUser}
          updateUsers={updateUsers}
          toggleModal={toggleUserStatusModal}
        />
      )}
    </ContentBox>
  );
};

export default UsersTable;

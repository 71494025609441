import React, { Fragment } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "../EnhancedTableHead";
import UserAction from "../UserAction";

const formatDateAsText = inputDate => {
  if (!inputDate) {
    return "-";
  }

  const date = moment(new Date(inputDate), "YYYY-MM-DD HH:mm");
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  const wasUpdatedToday = date.isSame(today, "d");
  const wasUpdatedYesterday = date.isSame(yesterday, "d");
  const updatedDate = date.format("YYYY-MM-DD");
  const updatedHours = date.format("HH:mm");

  return (
    <React.Fragment>
      {wasUpdatedToday ? "Idag" : wasUpdatedYesterday ? "Igår" : updatedDate}
      <em
        style={{
          display: "block",
          color: "#95a4ac",
          fontStyle: "normal",
          lineHeight: "15px"
        }}
      >
        {updatedHours}
      </em>
    </React.Fragment>
  );
};

const AllActivityTable = props => {
  const {
    isMobileOrTablet,
    loaded,
    columns,
    logs,
    users,
    clients,
    numCases,
    page,
    setPage,
    filters,
    setFilters,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    handleClientClick
  } = props;

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "flex-start"
      }}
    >
      <CustomToolbar
        isMobileOrTablet={isMobileOrTablet}
        filters={filters}
        users={users}
        clients={clients}
        setFilters={setFilters}
        setPage={setPage}
      />
      <TableContainer component={Box} sx={{ overflowY: "hidden" }}>
        <Box
          sx={{
            display: "block",
            width: "100%",
            minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
            mt: 1,
            border: 0,
            overflow: "auto"
          }}
        >
          <Table
            stickyHeader
            className="mui-table"
            aria-label="Futur Table"
            sx={{
              minWidth: 700,
              border: 0,
              tableLayout: "fixed"
            }}
          >
            <EnhancedTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {!loaded ? (
                <TableRow
                  sx={{
                    height: "100%",
                    "&:last-child td": { border: 0 }
                  }}
                >
                  <TableCell colSpan={columns.length}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: tableHeightPerTotalPages[rowsPerPage] * 0.7
                      }}
                    >
                      <ActivityIndicator color="#4cb7d5" busy />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {logs &&
                    logs
                      .sort(getComparator(order, orderBy))
                      .map((entry, key) => {
                        return (
                          <Fragment key={key}>
                            <TableRow
                              sx={{
                                height: 48,
                                ...(key % 2 !== 0 && {
                                  "& > td": { background: "#f5f9ff" }
                                })
                              }}
                            >
                              <TableCell
                                sx={{
                                  p: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                <Typography variant="tableCell">
                                  {formatDateAsText(entry.Created)}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter"
                                }}
                              >
                                <Typography variant="tableCell">
                                  <span>
                                    {entry.UserFirstName} {entry.UserLastName}
                                  </span>
                                  <em
                                    style={{
                                      display: "block",
                                      color: "#95a4ac",
                                      fontStyle: "normal",
                                      lineHeight: "15px"
                                    }}
                                  >
                                    {entry.ResellerName}
                                  </em>
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter",
                                  textAlign: "right"
                                }}
                              >
                                <Typography variant="tableCell">
                                  <UserAction
                                    log={entry}
                                    clients={clients}
                                    handleClientClick={handleClientClick}
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </Box>
        <CustomTableFooter
          loaded={loaded}
          type="userLogs"
          totalCases={numCases}
          data={logs}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </Box>
  );
};

export default AllActivityTable;

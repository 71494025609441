import React, { Fragment } from "react";

import { Box } from "@mui/material";
import UsersTable from "@cobComponents/UsersTable";
import CompaniesTable from "@cobComponents/CompaniesTable";
import UserActivityView from "@views/Dashboard/UserActivityView";
import { MenuDrawerConsumer } from "@contexts/MenuDrawerProvider";

const DashboardAdmin = props => {
  const menuDrawerCtxt = MenuDrawerConsumer();
  const selectedSection = menuDrawerCtxt.state.selectedChild;
  const options = props.partner.modules.find(module => module.key === "admin")
    ?.widgets[0];
  const userActivityOptions = {
    minActivityLogTime: options.minActivityLogTime
  };

  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          marginBottom: 2
        }}
      >
        {selectedSection === "users" && (
          <UsersTable user={props.user} options={options} />
        )}
        {selectedSection === "user_companies" && <CompaniesTable />}
        {selectedSection === "user_activity" && (
          <UserActivityView options={userActivityOptions} {...props} />
        )}
      </Box>
    </Fragment>
  );
};

export default DashboardAdmin;
